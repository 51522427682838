@tailwind base;

@tailwind components;

@tailwind utilities;


@font-face {
    font-family: 'IRANYekan';
    src: local('IRANYekan'), url(../public/fonts/woff/IRANYekanWebRegular.woff) format('woff');
}
*:focus {
    outline: none;
}
@layer base {
    body {
        direction: rtl;
    }
}